import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import Contacts from "../views/Contacts.vue";
import Cooperation from "../views/Cooperation.vue";
import Home from "../views/Home.vue";
import Investors from "../views/Investors.vue";
import Projects from "../views/Projects.vue";
import ProjectsMobile from "../views/ProjectsMobile.vue";
import Services from "../views/Services.vue";
import Support from "../views/Support.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      description: "Home",
      ogTitle: "Home -  Atelier A1",
      ogDescription: "Welcome to the homepage.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/projects",
    name: "ProjectsMobile",
    component: ProjectsMobile,
    meta: {
      title: "Projects",
      description: "Projects",
      ogTitle: "Projects - Atelier A1",
      ogDescription: "Welcome to the Projects of My Website.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      title: "Contacts",
      description: "Contacts ",
      ogTitle: "Contact Us -  Atelier A1",
      ogDescription: "Get in touch with us through our contact page.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
    meta: {
      title: "About ",
      description: "About ",
      ogTitle: "About Us - Atelier A1",
      ogDescription: "Learn more about us and our mission.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    meta: {
      title: "Services ",
      description: "Services ",
      ogTitle: "Our Services -  Atelier A1",
      ogDescription: "Explore the services we offer to our clients.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: Cooperation,
    meta: {
      title: "Cooperation ",
      description: "Cooperation ",
      ogTitle: "Cooperation -  Atelier A1",
      ogDescription: "Join us in our cooperative efforts for success.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/investors",
    name: "Investors",
    component: Investors,
    meta: {
      title: "Investors",
      description: "Investors",
      ogTitle: "Investors -  Atelier A1",
      ogDescription: "Join us in our Investors efforts for success.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      title: "Support",
      description: "Support",
      ogTitle: "Support -  Atelier A1",
      ogDescription: "Get support and assistance from our team.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
  {
    path: "/project/:id",
    component: Projects,
    name: "Project",
    meta: {
      title: "Project",
      description: "Project",
      ogTitle: "Project -  Atelier A1",
      ogDescription: "Details about our project.",
      ogImage: "/img/project1.png", // Provide the correct image path
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history", // Enable history mode
});

// Add the navigation guard here
router.beforeEach((to, from, next) => {
  // Set document title
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Set Open Graph meta tags
  const ogTitle = to.meta.ogTitle || to.meta.title;
  const ogDescription = to.meta.ogDescription || to.meta.description;
  const ogImage = to.meta.ogImage || "/img/project1.png"; // Fallback image

  // Update meta tags in the document
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", ogTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", ogDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", ogImage);

  next();
});

export default router;
