<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content content-2">
        <div class="row">
          <div
            class="col-lg-12 col-xl-12"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div class="project-info">
              <h1 class="section-title">
                {{ items.investors.title[$store.state.lang] }}
              </h1>
              <p>{{ items.investors.description[$store.state.lang] }}</p>
            </div>
          </div>
          <!-- <div
            class="col-lg-6 col-xl-8 pl-xl-4"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img src="/img/project3.jpeg" class="w-100" alt="Investor" />
          </div> -->
        </div>

        <div class="border-grey mt-4 d-md-none"></div>
        <br />
        <br />
        <div
          class="other-projects about-us-members cooperation-partners investor"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <swiper
            class="swiper gallery-top"
            :options="swiperOthersTop"
            ref="swiperTopOthers"
            v-if="shouldRenderSwiper"
          >
            <swiper-slide v-for="(item, i) in items.investorsLogo" :key="i">
              <div class="project-item">
                <a :href="item.investor_url" target="_blank">
                  <img :src="item.logo" alt="" />
                </a>
              </div>
            </swiper-slide>

            <div
              class="swiper-button-next swiper-button-white"
              slot="button-next"
            ></div>
            <div
              class="swiper-button-prev swiper-button-white"
              slot="button-prev"
            ></div>
          </swiper>

          <div class="swiper gallery-top" v-else>
            <div class="investors">
              <a
                :href="item.investor_url"
                v-for="(item, i) in items.investorsLogo"
                :key="i"
                target="_blank"
              >
                <img :src="item.logo" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Header from "../components/Header.vue";
export default {
  name: "Investors",
  components: { Header, Swiper, SwiperSlide },
  data() {
    return {
      showAnimation: true,
      items: [],
      projectId: null,
      lang: this.$store.state.lang,
      translate: null,
      swiperOthersTop: {
        loop: false,
        loopedSlides: 0, // looped slides should be the same
        spaceBetween: 4,
        slidesPerView: 4.3,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 2.5,
          },
          1200: {
            slidesPerView: 3.6,
          },
        },
        on: {
          init: function () {
            document.querySelector(".swiper-button-prev").style.display =
              "none";
          },
          slideChange: function () {
            if (this.activeIndex > 0) {
              document.querySelector(".swiper-button-prev").style.display =
                "block";
            } else {
              document.querySelector(".swiper-button-prev").style.display =
                "none";
            }
          },
        },
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    shouldRenderSwiper() {
      return window.innerWidth > 768;
    },
  },
  watch: {
    // Watch for window resize and update the computed property
    mounted() {
      window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
    methods: {
      handleResize() {
        this.$forceUpdate(); // Force update to trigger computed property re-evaluation
      },
    },
  },
  mounted() {
    this.fetchData();
    // setTimeout(() => {
    //   this.showAnimation = false;
    // }, 1500);
  },

  methods: {
    fetchData() {
      axios
        .get("common.json")
        .then((response) => {
          this.items = response.data;
          if (this.items) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/main";
</style>
