<template>
  <div id="app" :class="{ main: true, menuOpen: $store.state.sidebarIsOpen }">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  created() {
    this.$store.commit("getDefaultLang");
    // const password = prompt("Please enter your password:");

    // // Check if the password is correct
    // if (password === "112233") {
    //   // Redirect to the main page (assuming the route is '/main')
    //   this.$router.push({ path: "/" });
    // } else {
    //   // Optionally, you can alert the user if the password is incorrect
    //   alert("Access denied.");
    // }
  },
};
</script>
<style>
.main {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  background-color: #121213;
  min-height: 100vh;
  background-color: #121213;
}
html {
  background-color: #121213;
}
</style>
