import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vue from "vue";
import Vuex from "vuex";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

Vue.use(Vuex, IconsPlugin, BootstrapVue);

// import * as VueGoogleMaps from "vue2-google-maps";

// Vue.use(Vuex, IconsPlugin, BootstrapVue, VueGoogleMaps, {
//   load: {
//     key: "AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw",
//     libraries: "places", // This is required if you use the Autocomplete plugin
//     // OR: libraries: 'places,drawing'
//     // OR: libraries: 'places,drawing,visualization'
//     // (as you require)

//     //// If you want to set the version, you can do so:
//     // v: '3.26',
//   },
// });

export default new Vuex.Store({
  state: {
    // lang: localStorage.getItem("lang"),
    lang: localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : localStorage.setItem("lang", "cz"),
    sidebarIsOpen: false,
  },
  mutations: {
    setLang(state, newLang) {
      state.lang = newLang;
    },
    getDefaultLang(state) {
      state.lang = localStorage.getItem("lang");
    },
    setSidebarPosition(state, newSidebarPosition) {
      state.sidebarIsOpen = newSidebarPosition;
    },
  },
  actions: {
    updateLang({ commit }, newLang) {
      commit("setLang", newLang);
    },
    updateSidebarPosition({ commit }, newSidebarPosition) {
      commit("setSidebarPosition", newSidebarPosition);
    },
  },
});
