<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content">
        <p class="projects-title">
          {{ language.projects[$store.state.lang] }}
        </p>
        <div class="projects">
          <router-link
            :to="'/project/' + item.id"
            v-for="(item, i) in items"
            :key="i"
            class="project-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img :src="item.image_main" alt="" />
            <div>
              <p>{{ item.title[$store.state.lang] }}</p>
              <span>{{ item.description[$store.state.lang] }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/Header.vue";

export default {
  name: "ProjectMobile",
  components: { Header },
  data() {
    return {
      showAnimation: true,
      items: [],
      language: [],
      lang: this.$store.state.lang,
    };
  },
  computed: {
    currentLang() {
      return localStorage.getItem("lang");
    },
  },

  mounted() {
    this.fetchProjects();
    this.fetchLanguage();
  },
  methods: {
    fetchProjects() {
      axios
        .get("projects.json")
        .then((response) => {
          this.items = response.data;
          if (this.items.length && this.language) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchLanguage() {
      axios
        .get("common.json")
        .then((response) => {
          this.language = response.data;
          if (this.items.length && this.language) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/main";
</style>
