import "@babel/polyfill";
import axios from "axios";
import "mutationobserver-shim";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
// import { GmapMarker } from "vue2-google-maps/src/components/marker";
import App from "./App.vue";
import "./plugins/bootstrap-vue";
import router from "./router";
import store from "./store";

// axios.defaults.baseURL = "http://localhost:8080/";
axios.defaults.baseURL = "/";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA2Ca5lnSgNvucGhbeZW0bfsPOruL5FNK0",
    libraries: "places",
  },
});

// Vue.component("GmapMarker", GmapMarker);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
