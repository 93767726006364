<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content">
        <div class="row">
          <div class="col-md-4">
            <div class="project-info">
              <h1 class="section-title">
                {{ items.services[$store.state.lang] }}
              </h1>
              <p>{{ items.servicesText[$store.state.lang] }}</p>
            </div>
          </div>
          <div class="col-md-8 d-none d-md-block">
            <div class="services-top">
              <p v-for="(item, i) in items.servicesMenu" :key="i">
                {{ item.title[$store.state.lang] }}
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            data-aos="zoom-in"
            data-aos-duration="1000"
            class="col-6 col-lg-6 col-xl-4 mb-2"
            v-for="(item, i) in items.servicesItems"
            :key="i"
            :class="{ 'col-12 col-lg-6 col-xl-4 mb-2': (i + 1) % 3 === 0 }"
          >
            <div class="service-item">
              <div>
                <h2>
                  {{ item.number }}
                </h2>
                <p>
                  {{ item.title[$store.state.lang] }}
                </p>
              </div>
              <div>
                <p>
                  {{ item.title[$store.state.lang] }}
                </p>
                <span>
                  {{ item.description[$store.state.lang] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/Header.vue";
export default {
  name: "Services",
  components: { Header },
  data() {
    return {
      showAnimation: true,
      items: [],
      lang: this.$store.state.lang,
      translate: null,
    };
  },

  mounted() {
    this.fetchLanguge();

    // setTimeout(() => {
    //   this.showAnimation = false;
    // }, 1500);
  },

  methods: {
    fetchLanguge() {
      axios
        .get("common.json")
        .then((response) => {
          this.items = response.data;
          if (this.items) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/main";
</style>
