<template>
  <div class="left-sidebar">
    <div>
      <b-sidebar
        id="sidebar-backdrop"
        class="sidebar-open"
        :backdrop-variant="variant"
        width="341px"
        backdrop
        shadow
        no-header
        body-class="sidebar-open"
        :key="menu"
        no-close-on-route-change="true"
        v-model="sidebarChange"
      >
        <div class="left-sidebar">
          <div class="left-sidebar-top">
            <div class="top d-flex justify-content-between mb-4">
              <b-button
                v-b-toggle.sidebar-backdrop
                class="btn p-0 border-0 bg-transparent"
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 19.25L10.5465 9.70346M10.5465 9.70346L19.5 0.75M10.5465 9.70346L1.5 0.75M10.5465 9.70346L19.5 18.5648"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </b-button>

              <button
                class="btn p-0 border-0 btn-lang text-uppercase"
                @click="updateLanguage()"
              >
                {{ updateLang }}
              </button>
            </div>
            <div class="border-grey"></div>
            <div v-for="(item, i) in menu" :key="i">
              <router-link :to="item.link" class="menu-link">
                {{ item.title[currentLang] }}
              </router-link>
              <div class="border-grey"></div>
            </div>
          </div>
          <div>
            <div class="copyright">
              <div class="border-grey mb-3 d-none d-md-block"></div>
              Copyright © 2024 <br />
              A 1 spol. s.r.o. <br />
              <span>
                Designed by
                <a href="https://tamada.design/"> tamada.design</a>
              </span>
            </div>
          </div>
        </div>
      </b-sidebar>
      <div class="top d-flex justify-content-between">
        <b-button
          v-b-toggle.sidebar-backdrop
          class="btn p-0 border-0 bg-transparent"
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_244_578)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7577 2.69617H0.75769V1.18079H12.7577V2.69617Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.9423 8.75769H0.75769V7.24231H18.9423V8.75769Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7577 14.8192H0.75769V13.3038H12.7577V14.8192Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_244_578">
                <rect
                  width="19.7"
                  height="15.2"
                  fill="white"
                  transform="translate(0 0.400024)"
                />
              </clipPath>
            </defs>
          </svg>
        </b-button>

        <button
          class="btn p-0 border-0 btn-lang text-uppercase"
          @click="updateLanguage()"
        >
          {{ updateLang }}
        </button>
      </div>

      <div v-if="!isMobile" class="card-sidebar">
        <router-link to="/" class="d-flex">
          <div
            class="card-sidebar-img d-none d-md-block"
            style="background-image: url(/img/sidebar-1.png)"
          ></div>

          <img src="/img/sidebar-1.png" class="d-md-none w-100" alt="" />
        </router-link>

        <p>Architektonicko-projekční Ateliér A1</p>
        <span>
          Piano business center <br />
          Lidická tř. 2331/6a, 370 01 České Budějovice
        </span>
        <p>Contact</p>
        <a href="mailto:info@atelier-a1.cz">info@atelier-a1.cz</a>
      </div>
      <div v-if="!isMobile" class="border-grey mt-3"></div>
      <div v-if="!isMobile" class="card-sidebar pt-4">
        <div
          class="card-sidebar-img d-none d-md-block"
          style="background-image: url(/img/sidebar-2.jpg)"
        ></div>
        <img src="/img/sidebar-2.jpg" class="d-md-none" alt="" />

        <p>Ing. František Adler</p>
        <span> CEO </span>
        <a href="mailto:info@atelier-a1.cz">adler@atelier-a1.cz</a>
      </div>
      <div v-if="!isMobile" class="border-grey mt-4 d-md-none"></div>
    </div>
    <div class="copyright d-none d-md-block">
      <div class="border-grey mb-3"></div>
      Copyright © 2024 <br />
      A 1 spol. s.r.o.
    </div>
  </div>
</template>
<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Header",
  data() {
    return {
      variant: "dark",
      menu: [],
      page: "/",
      isMobile: false,
      sidebarChange: false,
    };
  },
  watch: {
    sidebarChange(newValue) {
      this.$store.dispatch("updateSidebarPosition", newValue);
    },
  },
  computed: {
    updateLang() {
      return this.$store.state.lang === "en" ? "cz" : "en";
    },
    currentLang() {
      return this.$store.state.lang === "en" ? "en" : "cz";
    },
  },
  created() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWindowWidth);
    this.$store.dispatch("updateSidebarPosition", false);
  },
  mounted() {
    this.fetchMenu();
    this.page = this.$route.path;
  },

  methods: {
    checkWindowWidth() {
      this.isMobile = window.innerWidth < 768;

      if (this.isMobile && this.$route.path === "/") {
        this.isMobile = false;
      }
    },
    updateLanguage() {
      if (localStorage.getItem("lang") === "en") {
        localStorage.setItem("lang", "cz");
        this.$store.dispatch("updateLang", "cz");
      } else if (localStorage.getItem("lang") === "cz") {
        localStorage.setItem("lang", "en");
        this.$store.dispatch("updateLang", "en");
      } else {
        this.$store.dispatch("updateLang", "en");
      }
    },
    fetchMenu() {
      axios
        .get("common.json")
        .then((response) => {
          this.menu = response.data.menu;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-open > div {
  background-color: #232323;
}
@import "../scss/main";
</style>
