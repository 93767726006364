<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content pb-md-1">
        <div class="row">
          <div class="col-md-4" data-aos="fade-right" data-aos-duration="1000">
            <div class="project-info">
              <h1 class="section-title">
                {{ filteredItem.title[$store.state.lang] }}
              </h1>
              <p>{{ filteredItem.description[$store.state.lang] }}</p>
              <a class="section-link" :href="filteredItem.map_url">{{
                filteredItem.show_on_map[$store.state.lang]
              }}</a>
            </div>
          </div>
          <div class="col-md-8" data-aos="fade-left" data-aos-duration="1000">
            <div class="thumb-example">
              <swiper
                class="swiper gallery-thumbs"
                :options="swiperOptionThumbs"
                ref="swiperThumbs"
              >
                <swiper-slide
                  v-for="(item, i) in filteredItem.image_gallery"
                  :key="i"
                >
                  <img :src="item" alt="" />
                </swiper-slide>
              </swiper>
              <swiper
                class="swiper gallery-top"
                :options="swiperOptionTop"
                ref="swiperTop"
              >
                <swiper-slide
                  v-for="(item, i) in filteredItem.image_gallery"
                  :key="i"
                >
                  <img :src="item" alt="" />
                </swiper-slide>

                <div
                  class="swiper-button-next swiper-button-white"
                  slot="button-next"
                ></div>
                <div
                  class="swiper-button-prev swiper-button-white"
                  slot="button-prev"
                ></div>
              </swiper>
            </div>
          </div>
        </div>
        <div class="border-grey d-md-none"></div>

        <div
          class="other-projects others"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="top">
            <p class="section-title mb-0">
              {{ translate.otherProjects[$store.state.lang] }}
            </p>
            <router-link class="section-link" to="/">{{
              translate.showAll[$store.state.lang]
            }}</router-link>
          </div>

          <swiper
            class="swiper gallery-top d-none d-md-block"
            :options="swiperOthersTop"
            ref="swiperTopOthers"
          >
            <swiper-slide v-for="(item, i) in filteredItems" :key="i">
              <router-link :to="'/project/' + item.id" class="project-item">
                <img :src="item.image_main" alt="" />
                <div>
                  <p>{{ item.title[$store.state.lang] }}</p>
                  <span>{{ item.description[$store.state.lang] }}</span>
                </div>
              </router-link>
            </swiper-slide>

            <div
              class="swiper-button-next swiper-button-white"
              slot="button-next"
            ></div>
          </swiper>

          <div class="projects d-md-none">
            <router-link
              :to="'/project/' + item.id"
              v-for="(item, i) in filteredItems"
              :key="i"
              class="project-item"
            >
              <img :src="item.image_main" alt="" />
              <div>
                <p>{{ item.title[$store.state.lang] }}</p>
                <span>{{ item.description[$store.state.lang] }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Header from "../components/Header.vue";
export default {
  name: "Projects",
  components: { Header, Swiper, SwiperSlide },
  data() {
    return {
      showAnimation: true,
      items: [],
      filteredItem: {},
      projectId: null,
      lang: this.$store.state.lang,
      translate: null,
      swiperOthersTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 4,
        slidesPerView: 4.3,

        navigation: {
          nextEl: ".swiper-button-next",
        },
        breakpoints: {
          // When window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          // When window width is >= 480px
          480: {
            slidesPerView: 1,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
          },
          // When window width is >= 992px
          992: {
            slidesPerView: 3,
          },
          // When window width is >= 1200px
          1200: {
            slidesPerView: 4.3,
          },
        },
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        touchRatio: 0.2,
        direction: "vertical",
        slidesPerView: 4,
        slideToClickedSlide: true,
      },
    };
  },

  watch: {
    "$route.params.id": "fetchData",
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.id !== this.filteredItem.id);
    },
  },

  mounted() {
    this.updateSwiperOptionThumbs();
    this.projectId = this.$route.params.id;

    this.fetchLanguge();

    this.fetchData();
    setTimeout(() => {
      this.showAnimation = false;
    }, 1000);
  },
  updated() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      if (swiperTop && swiperThumbs) {
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      }
    });
  },
  methods: {
    fetchLanguge() {
      axios
        .get("common.json")
        .then((response) => {
          this.translate = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchProjects() {
      axios
        .get("projects.json")
        .then((response) => {
          this.items = response.data;
          this.filteredItem = this.items.find(
            (item) => item.id == this.projectId
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchData() {
      this.projectId = this.$route.params.id;
      this.fetchProjects();
    },
    updateSwiperOptionThumbs() {
      // Check window width and update the direction property accordingly
      if (window.innerWidth <= 767.98) {
        this.swiperOptionThumbs.direction = "horizontal";
      } else {
        this.swiperOptionThumbs.direction = "vertical";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../scss/main";
</style>
