<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content pb-3">
        <div class="row mb-4">
          <div class="col-md-6" data-aos="fade-right" data-aos-duration="1000">
            <h1 class="section-title">
              {{ contacts.contacts[$store.state.lang] }}
            </h1>
            <div class="row">
              <div
                class="col-lg-6"
                v-for="(item, i) in contacts.contactMenu"
                :key="i"
              >
                <div class="contact-info">
                  <p v-html="item.title[$store.state.lang]"></p>
                  <span v-html="item.text[$store.state.lang]"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" data-aos="fade-left" data-aos-duration="1000">
            <!-- <GmapMap
              :center="{ lat: 50.0945751041199, lng: 14.455739879748469 }"
              :zoom="13"
              :options="{
                styles: [
                  {
                    featureType: 'administrative',
                    elementType: 'geometry',
                    stylers: [
                      {
                        visibility: 'off',
                      },
                    ],
                  },
                  {
                    featureType: 'poi',
                    stylers: [
                      {
                        visibility: 'off',
                      },
                    ],
                  },
                  {
                    featureType: 'road',
                    elementType: 'labels.icon',
                    stylers: [
                      {
                        visibility: 'on',
                      },
                    ],
                  },
                  {
                    featureType: 'transit',
                    stylers: [
                      {
                        visibility: 'off',
                      },
                    ],
                  },
                  {
                    featureType: 'all',
                    elementType: 'labels.text.fill',
                    stylers: [
                      {
                        color: '#828282',
                      },
                    ],
                  },
                  {
                    featureType: 'all',
                    elementType: 'labels.text.stroke',
                    stylers: [
                      {
                        color: '#000000',
                      },
                      {
                        lightness: 13,
                      },
                    ],
                  },
                  {
                    featureType: 'administrative',
                    elementType: 'geometry.fill',
                    stylers: [
                      {
                        color: '#000000',
                      },
                    ],
                  },
                  {
                    featureType: 'administrative',
                    elementType: 'geometry.stroke',
                    stylers: [
                      {
                        color: '#144b53',
                      },
                      {
                        lightness: 14,
                      },
                      {
                        weight: 1.4,
                      },
                    ],
                  },
                  {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#343332',
                      },
                    ],
                  },
                  {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [
                      {
                        color: '#0c4152',
                      },
                      {
                        lightness: 5,
                      },
                    ],
                  },
                  {
                    featureType: 'road.highway',
                    elementType: 'geometry.fill',
                    stylers: [
                      {
                        color: '#454545',
                      },
                    ],
                  },
                  {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [
                      {
                        color: '#454545',
                      },
                      {
                        lightness: 25,
                      },
                    ],
                  },
                  {
                    featureType: 'road.arterial',
                    elementType: 'geometry.fill',
                    stylers: [
                      {
                        color: '#454545',
                      },
                    ],
                  },
                  {
                    featureType: 'road.arterial',
                    elementType: 'geometry.stroke',
                    stylers: [
                      {
                        color: '#0b3d51',
                      },
                      {
                        lightness: 16,
                      },
                    ],
                  },
                  {
                    featureType: 'road.local',
                    elementType: 'geometry',
                    stylers: [
                      {
                        color: '#454545',
                      },
                    ],
                  },
                  {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#146474',
                      },
                    ],
                  },
                  {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#191a1a',
                      },
                    ],
                  },
                ],
              }"
              class="contact-map"
            >
              <GmapMarker
                :position="{ lat: 50.0945751041199, lng: 14.455739879748469 }"
                :zIndex="999"
                :icon="pin"
                :label="{
                  className: 'custom-marker',
                  text: 'Kancelář',
                  labelVisible: true,
                  // Etiketi gizlemek için
                }"
                :draggable="false"
              />
            </GmapMap> -->

            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2619.208900203157!2d14.4745273!3d48.968547699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47734f34d633e2ff%3A0x92cb4d8e89ba577f!2sPiano%20business%20center!5e0!3m2!1scs!2scz!4v1724873697117!5m2!1scs!2scz"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="border-grey mb-4 d-md-none"></div>
        <div class="row" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-5 order-2 order-md-1">
            <div class="project-info">
              <h2 class="section-title">
                {{ contacts.contactsFormTitle[$store.state.lang] }}
              </h2>
              <p>
                {{ contacts.contactsFormText[$store.state.lang] }}
              </p>
            </div>
            <form class="contact-form" @submit.prevent="submitForm">
              <div class="form-group">
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.name"
                />
                <label>
                  {{ contacts.placeholders.name[$store.state.lang] }}
                </label>
              </div>
              <div class="form-group">
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.firma"
                />
                <label>
                  {{ contacts.placeholders.firma[$store.state.lang] }}
                </label>
              </div>
              <div class="form-group">
                <input
                  required
                  type="email"
                  class="form-control"
                  v-model="form.email"
                />
                <label>
                  {{ contacts.placeholders.email[$store.state.lang] }}
                </label>
              </div>
              <div class="form-group">
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.phone"
                />
                <label>
                  {{ contacts.placeholders.phone[$store.state.lang] }}
                </label>
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  required
                  v-model="form.message"
                ></textarea>
                <label>
                  {{ contacts.placeholders.message[$store.state.lang] }}
                </label>
              </div>
              <div class="contact-form-bottom">
                <b-form-checkbox
                  v-model="status"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Souhlasím se zpracováním osobních údajů.
                  <a href="">Zásady ochrany osobních údajů.</a>
                </b-form-checkbox>
                <button
                  class="btn-form-send"
                  v-bind:class="{ disabled: !this.status }"
                >
                  Odeslat zprávu
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-7 order-1 order-md-2">
            <div class="thumb-example">
              <swiper
                class="swiper gallery-top"
                :options="swiperOptionTop"
                ref="swiperTop"
              >
                <swiper-slide
                  v-for="(item, i) in contacts.contactGallery"
                  :key="i"
                >
                  <img :src="item.logo" alt="" />
                </swiper-slide>

                <div
                  class="swiper-button-next swiper-button-white"
                  slot="button-next"
                ></div>
                <div
                  class="swiper-button-prev swiper-button-white"
                  slot="button-prev"
                ></div>
              </swiper>
            </div>
          </div>
          <div class="col-md-12 mt-2"></div>
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import map_pin from "../assets/map_pin.svg";
import Header from "../components/Header.vue";
export default {
  name: "Projects",
  components: { Header, Swiper, SwiperSlide },

  data() {
    return {
      showAnimation: true,
      contacts: [],
      filteredItem: {},
      projectId: null,
      lang: this.$store.state.lang,
      translate: null,
      pin: map_pin,
      status: false,
      form: {
        name: "",
        firma: "",
        email: "",
        phone: "",
        message: "",
      },
      swiperOthersTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 4,
        slidesPerView: 4.3,

        navigation: {
          nextEl: ".swiper-button-next",
        },
        breakpoints: {
          // When window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          // When window width is >= 480px
          480: {
            slidesPerView: 1,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
          },
          // When window width is >= 992px
          992: {
            slidesPerView: 3,
          },
          // When window width is >= 1200px
          1200: {
            slidesPerView: 4.3,
          },
        },
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        touchRatio: 0.2,
        direction: "vertical",
        slidesPerView: 4,
        slideToClickedSlide: true,
      },
    };
  },

  mounted() {
    this.fetchLanguge();

    // setTimeout(() => {
    //   this.showAnimation = false;
    // }, 1500);
  },

  methods: {
    async submitForm() {
      try {
        const response = await axios.post(
          "https://atelier-a1.cz/dudus.php",
          this.form
        );
        console.log(response.status);
      } catch (error) {
        console.error("There was an error!", error);
      }
    },
    fetchLanguge() {
      axios
        .get("common.json")
        .then((response) => {
          this.contacts = response.data;
          if (this.contacts) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/main";

.custom-marker {
  font-family: Jost !important;
  font-size: 16px;
  padding: 20px;
  background-color: #e94234;
  padding: 8px 12px 8px 12px;
  border-radius: 2px;
  color: #fff !important;
  position: relative;
  margin-bottom: 70px;
  &::after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: calc(50% - 6px);
    border-top: 8px solid #e94234;
    border-bottom: 8px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}

.gm-style-mtc-bbw {
  display: none !important;
}

.gm-fullscreen-control {
  display: none !important;
}

.gm-svpc {
  display: none !important;
}
.gmnoprint {
  display: none !important;
}
.gm-style-cc {
  display: none !important;
}
.gmnoprint {
  display: none !important;
}
</style>
