<template>
  <div>
    <div v-if="showAnimation" class="animation">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
      </div>
    </div>
    <div v-if="!showAnimation" class="wrapper">
      <Header />
      <div class="content pt-4 d-none d-md-block">
        <!-- <p class="projects-title"> -->
        <!-- {{ language.investors[$store.state.lang] }} -->
        <!-- </p> -->
        <!-- <div class="investors">
          <a
            :href="item.investor_url"
            v-for="(item, i) in language.investorsLogo"
            :key="i"
          >
            <img :src="item.logo" alt="" />
          </a>
        </div> -->
        <p class="projects-title mb-4">
          {{ language.mainPage[$store.state.lang] }}
        </p>
        <div>
          <img src="/img/a1.png" alt="" class="w-100" />
        </div>
      </div>
      <div class="copyright d-md-none">
        Copyright © 2024 <br />
        A 1 spol. s r. o.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/Header.vue";

export default {
  name: "Home",
  components: { Header },
  data() {
    return {
      showAnimation: true,
      items: [],
      language: [],
      lang: this.$store.state.lang,
    };
  },
  computed: {
    currentLang() {
      return localStorage.getItem("lang");
    },
  },

  mounted() {
    this.fetchProjects();
    this.fetchLanguage();
  },
  methods: {
    fetchProjects() {
      axios
        .get("projects.json")
        .then((response) => {
          this.items = response.data;
          if (this.items.length && this.language) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchLanguage() {
      axios
        .get("common.json")
        .then((response) => {
          this.language = response.data;
          if (this.items.length && this.language) {
            this.showAnimation = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/main";
</style>
